import * as React from "react"
import fetch from "isomorphic-fetch"
import dayjs from "dayjs";
import { v4 as uuidv4 } from 'uuid';
import Client from "shopify-buy/index.unoptimized.umd"
import { getZapietId } from "../utils/zapiet";
import useWindowSize from "../utils/useWindowSize";

const client = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN
  },
  fetch
)

client.config.graphqlUrl = `https://${client.config.domain}/api/${client.config.apiVersion}/graphql.json`;
const defaultValues = {
  cart: [],
  isOpen: false,
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  client,
  checkout: {
    lineItems: [],
  },
}

export const StoreContext = React.createContext(defaultValues)

const isBrowser = typeof window !== `undefined`
const localStorageKey = `shopify_checkout_id`
const localCreateYourOwnKey = `createyourown`;

export const StoreProvider = ({ children }) => {
  const [checkout, setCheckout] = React.useState(defaultValues.checkout)
  const [lineItemProducts, setLineItemProducts] = React.useState();
  const [username, setUsername] = React.useState();  
  const [createYourOwn, setCreateYourOwn ] = React.useState();
  const [loading, setLoading] = React.useState(false)
  const [ locations, setLocations ] = React.useState(false);
  const [ locationCalendars, setLocationCalendars ] = React.useState({});
  const [didJustAddToCart, setDidJustAddToCart] = React.useState(false)
  const [cartOpen, setCartOpen] = React.useState(false);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [ footerTrigger, setFooterTrigger ] = React.useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);
  const [loginOpen, setLoginOpen] = React.useState(false);
  const [conceptOpen, setConceptOpen] = React.useState(false);
  const [conceptGranolaOpen, setConceptGranolaOpen] = React.useState(false);
  const [ width ] = useWindowSize();
  const toggleTime = React.useRef(null);

  const setCheckoutItem = (checkout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id)
    }

    setCheckout(checkout)
    getLineItemProducts(checkout);
  }

  const setCreateYourOwnItem = (create) => {
    if (isBrowser) {
      localStorage.setItem(localCreateYourOwnKey,JSON.stringify(create))
    }

    setCreateYourOwn(create)
  }

  React.useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout = await client.checkout.fetch(
            existingCheckoutID
          )
          if (!existingCheckout.completedAt) {
            const storage = JSON.parse(localStorage.getItem(localCreateYourOwnKey));
            setCreateYourOwnItem(storage || {})
            setCheckoutItem(existingCheckout)
            return
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, null)
        }
      }

      const newCheckout = await client.checkout.create()

      setCreateYourOwnItem({})
      setCheckoutItem(newCheckout)
    }

    setUsername(getCurrentUsername());
    initializeCheckout()
    
  }, [])

  const addMethodToCheckout = (id,date,method,needsBag) => {
    const checkoutID = checkout.id;
    const location = locations.locations ? locations.locations.filter((location) => {
      return location.id === Number(id);
    })[0] : locations;

    const theDate = dayjs(date);
    const pickUpDate = theDate.format('YYYY/MM/DD');
    //const pickUpTime = theDate.format('h:mm A');
    let input;

    if(method === 'pickup') {
      input = {customAttributes: [
        {key: "Checkout-Method", value: method},
        {key: "Pickup-Location-Id", value: id.toString()},
        {key: "Pickup-Date", value: pickUpDate},
        {key: "Custom-Attribute-1", value: location.company_name},
        {key: "Pickup-Location-Company", value: location.company_name},
        {key: "Pickup-Location-Address-Line-1", value: location.address_line_1},
        {key: "Pickup-Location-City", value: location.city},
        {key: "Pickup-Location-Region", value: location.region},
        {key: "Pickup-Location-Postal-Code", value: location.postal_code},
        {key: "Pickup-Location-Country", value: location.country},
        {key: "Pickup-More-Info", value: location.more_information},
        {key: "Bag", value: needsBag}
      ]};
    } else if (method === 'delivery') {
      input = {customAttributes: [
      {key: "Checkout-Method", value: method},
      {key: "Delivery-Location-Id", value: id.toString()},
      {key: "Delivery-Date", value: pickUpDate},
      {key: "Custom-Attribute-1", value: location.company_name}
      ]};

    }
    


    

    
    return client.checkout.updateAttributes(checkoutID, input).then((res) => {
      setCheckout(res)
      console.log(res);
    });
  }

  const getLineItemProducts = (checkoutObject) => {
    const ids = checkoutObject.lineItems.reduce((acc,curr) => {
      acc.push(curr.variant.product.id);
      return acc;
    },[])
  
    return client.product.fetchMultiple(ids).then((products) => {
      setLineItemProducts(products);
      return products;
    });
  }

  const setNote = (note) => {
    const checkoutID = checkout.id;

    let input = { note: note};

    return client.checkout.updateAttributes(checkoutID, input).then((res) => {
      setCheckout(res)
    });

  }

  const setBag = (value) => {
    const checkoutID = checkout.id;

    let input = { customAttributes: [
      {key: "Bag", value: value},
    ]};

    return client.checkout.updateAttributes(checkoutID, input).then((res) => {
      setCheckout(res)
    });

  }

  const addDiscount = (discountCode) => {
    const checkoutID = checkout.id;
  
    // Add a discount code to the checkout
    return client.checkout.addDiscount(checkoutID, discountCode).then(res => {
      setCheckout(res)
      // Do something with the updated checkout
    });
  }

  const removeDiscount = () => {
    const checkoutID = checkout.id;
  
    // Add a discount code to the checkout
    return client.checkout.removeDiscount(checkoutID).then(res => {
      setCheckout(res)
      // Do something with the updated checkout
    });
  }


  const updateLineItemsWithZapietId = (method,location_id) => {
    const checkoutID = checkout.id
    const lineItemsToUpdate = checkout.lineItems.map((item) => {

      const customObject = item.customAttributes.reduce((acc,curr) => {
        if(curr.key === 'Create-Id') {
          acc.push({key: 'Create-Id', value: curr.value })
        }
        return acc;
      }, [])
      const newCustomObject = [{key: "_ZapietId", value: getZapietId({method:method,location_id:location_id})}, ...customObject ];

      return { id: item.id, customAttributes: newCustomObject }
    }) 

    return client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res)
      })
  }

  const addVariantToCart = (variantId, quantity) => {
    setLoading(true)

    const checkoutID = checkout.id

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ]

    return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res)

        return getLineItemProducts(res).then(()=>{
          setLoading(false)
          setDidJustAddToCart(true)
          setTimeout(() => setDidJustAddToCart(false), 3000)
        });
      })
  }

  const removeLineItem = (checkoutID, lineItemID) => {
    setLoading(true)

    const isArray = Array.isArray(lineItemID);



    return client.checkout
      .removeLineItems(checkoutID, isArray ? lineItemID : [lineItemID])
      .then((res) => {
        setCheckout(res)
        return getLineItemProducts(res).then(()=>{
          setLoading(false)
        });
      })
  }

  const updateLineItem = (checkoutID, lineItemID, quantity) => {
    setLoading(true)

    const lineItemsToUpdate = [
      { id: lineItemID, quantity: parseInt(quantity, 10) },
    ]

    return client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res)
        return getLineItemProducts(res).then(()=>{
          setLoading(false)
        });
      })
  }

  /**
   * Create Your Own
   */

   const addProductToCreate = (productId, data) => {
    const create = JSON.parse(localStorage.getItem(localCreateYourOwnKey));
    create[productId] = data;

    localStorage.setItem(localCreateYourOwnKey,JSON.stringify(create));
    setCreateYourOwn(create);
  }

  const addCreateToCart = () => {
    setLoading(true)
    const createId = uuidv4();
    const checkoutID = checkout.id

    const lineItemsToUpdate = [];
    
    Object.keys(createYourOwn).forEach((key) => {
      const product = createYourOwn[key];

      if(Number(product.qty) !== 0) {
        lineItemsToUpdate.push({
          variantId: product.variantId,
          quantity: parseInt(product.qty, 10),
          customAttributes: [
            {key: "Create-Id", value: createId},
          ]
        })
      } 
        
    })

    return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCreateYourOwnItem({})
        setCheckout(res)
        setLoading(false)
        setDidJustAddToCart(true)
        setTimeout(() => setDidJustAddToCart(false), 3000)
      })
  }



  const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
  };

  const getCurrentUsername = () => {
    return localStorage.getItem("username");
  };

  const getCustomerObject = () => {
    const accessToken = getCurrentUser() ? getCurrentUser().customerAccessToken.accessToken : null;

    if(!accessToken)
      return Promise.reject('no token found');

    const customerQuery = () => `
    query  {
      customer(customerAccessToken: "${accessToken}") {
        id
        firstName
        lastName
        acceptsMarketing
        email
        phone
        orders(first:10) {
          edges {
            node {
              id
              orderNumber
              currentTotalPrice {
                ... on MoneyV2 {
                  amount
                  currencyCode
                }
              }
              currentSubtotalPrice {
                ... on MoneyV2 {
                  amount
                  currencyCode
                }
              }
              totalShippingPriceV2 {
                ... on MoneyV2 {
                  amount
                  currencyCode
                }
              }
              discountApplications(first: 10)  {
              	... on DiscountApplicationConnection {
                  edges{
                    node {
                      allocationMethod
                      value {
                        ... on MoneyV2 {
                          amount
                          currencyCode
                        }
                        ... on PricingPercentageValue {
                          percentage
                        }
                      }

                    }
                  }
                }
              }
              lineItems(first: 10) {
                ... on OrderLineItemConnection {
                  edges {
                    node {
                      title
                      discountedTotalPrice {
                        ... on MoneyV2 {
                          amount
                          currencyCode
                        }
                      }
                      quantity
                      currentQuantity
                      customAttributes {
                        ... on Attribute {
                          key
                          value
                        }
                      }
                      variant {
                        ... on ProductVariant {
                          title
                        }
                      }
                    }
                  }
                }
              }
              fulfillmentStatus
              processedAt
            }
          }
          pageInfo {
            hasNextPage
          }
        }
      }
    }`

    const GRAPHQL_BODY  = () => {
      return {
      'async': true,
      'crossDomain': true,
      'method': 'POST',
      'headers': {
        'X-Shopify-Storefront-Access-Token': client.config.storefrontAccessToken,
        'Content-Type': 'application/graphql',
      },
      'body': customerQuery()
      };
    }

  
    return fetch(client.config.graphqlUrl, GRAPHQL_BODY())
      .then(res => { return res.json()})
      .then((res) => {
        return res;
      });

  }

  const customerCreate = (input) => {
    setLoading(true)

    const customerQuery = () => `
    mutation  {
      customerCreate(
        input: {
          firstName: "${input.firstName}",
          lastName: "${input.lastName}",
          email: "${input.email}",
          password: "${input.password}", 
            }
      ) {
        customer {
          id
          firstName
          lastName
          email
        }
      }
    }`

    const GRAPHQL_BODY  = () => {
      return {
      'async': true,
      'crossDomain': true,
      'method': 'POST',
      'headers': {
        'X-Shopify-Storefront-Access-Token': client.config.storefrontAccessToken,
        'Content-Type': 'application/graphql',
      },
      'body': customerQuery()
      };
    }

  
    return fetch(client.config.graphqlUrl, GRAPHQL_BODY())
      .then(res => {
        return res.json();
      }).then(res => {
        return res;
      });
  }

  const customerUpdate = (customer) => {
    setLoading(true)

    const accessToken = getCurrentUser() ? getCurrentUser().customerAccessToken.accessToken : null;

    if(!accessToken)
      return Promise.reject('no token found');

     const phone = customer.phone ? `"${customer.phone}"` : null;

    const customerQuery = () => `
    mutation  {
      customerUpdate(customer: {
        firstName: "${customer.firstName}",
        lastName: "${customer.lastName}",
        email: "${customer.email}",
        phone: ${phone}
      }, customerAccessToken: "${accessToken}")
     {
        customer {
          firstName
          lastName
        }
      }
    }`

    const GRAPHQL_BODY  = () => {
      return {
      'async': true,
      'crossDomain': true,
      'method': 'POST',
      'headers': {
        'X-Shopify-Storefront-Access-Token': client.config.storefrontAccessToken,
        'Content-Type': 'application/graphql',
      },
      'body': customerQuery()
      };
    }

  
    return fetch(client.config.graphqlUrl, GRAPHQL_BODY())
      .then(res => console.log(res));
  }

  const customerRecover = (email) => {
    setLoading(true);

    const customerQuery = () => `
    mutation  {
      customerRecover(
        email: "${email}" 
      ) {
        customerUserErrors {
          code,
          field,
          message
        }
      }
    }`


    const GRAPHQL_BODY  = () => {
      return {
      'async': true,
      'crossDomain': true,
      'method': 'POST',
      'headers': {
        'X-Shopify-Storefront-Access-Token': client.config.storefrontAccessToken,
        'Content-Type': 'application/graphql',
      },
      'body': customerQuery()
      };
    }

  
    return fetch(client.config.graphqlUrl, GRAPHQL_BODY())
      .then(res => { return res.json(); });

  }

  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("expiresAt");
    localStorage.removeItem('username');
    setUsername(null);
  }

  const login = (input) => {

    const loginQuery = () => `
    mutation{
      customerAccessTokenCreate(input: {
        email: "${input.email}",
        password: "${input.password}",
      }) {
        customerAccessToken {
          accessToken
          expiresAt
        }
        customerUserErrors {
          code
          field
          message
        }
      }
    }
    `

    const GRAPHQL_BODY  = () => {
      return {
      'async': true,
      'crossDomain': true,
      'method': 'POST',
      'headers': {
        'X-Shopify-Storefront-Access-Token': client.config.storefrontAccessToken,
        'Content-Type': 'application/graphql',
      },
      'body': loginQuery()
      };
    }

  
    return fetch(client.config.graphqlUrl, GRAPHQL_BODY())
      .then(res => { return res.json()})
      .then((res) => {
        const data = res.data.customerAccessTokenCreate;
        if(data.customerAccessToken) {
          localStorage.setItem("user", JSON.stringify(data));
          localStorage.setItem("expiresAt", data.expiresAt);
          const customerQuery = () => `
          query  {
            customer(customerAccessToken: "${data.customerAccessToken.accessToken}") {
              firstName
            }
          }`

          const GRAPHQL_BODY  = () => {
            return {
            'async': true,
            'crossDomain': true,
            'method': 'POST',
            'headers': {
              'X-Shopify-Storefront-Access-Token': client.config.storefrontAccessToken,
              'Content-Type': 'application/graphql',
            },
            'body': customerQuery()
            };
          }

          fetch(client.config.graphqlUrl, GRAPHQL_BODY())
          .then(res => { return res.json()})
          .then((res) => {
            const username = res.data.customer.firstName;
            localStorage.setItem("username", username);
            setUsername(username)
          })

         }
       return data; 
      });
  }

  const toggleCart = () => {
    if(!cartOpen === false) {
      document.body.style.overflow = 'unset';
    } else {
      document.body.style.overflow = 'hidden';
    }
    setCartOpen(!cartOpen);
  }

  const toggleMenu = (context) => {
    toggleTime.current = new Date();
    if(context) {
      setMenuOpen(context);
    } else {
      setMenuOpen(!menuOpen);
    }
  };

  const toggleMobileMenu = () => {
      if(!mobileMenuOpen === false) {
        document.body.style.overflow = 'unset';
      } else {
        document.body.style.overflow = 'hidden';
      }
      setMobileMenuOpen(!mobileMenuOpen);
  };

  const toggleLogin = () => {
      setLoginOpen(!loginOpen);
  };

  /**
   * Zapiet Delivery Slot and Pickup 
   */
  const getLocations = (method) => {

    const url = `https://api.zapiet.com/v4.0/${method}/locations?` + new URLSearchParams({
      shop: 'nute-foods.myshopify.com'
    });


    return fetch(url)
      .then((res) => {
        return res.json();
      }).then(data => {
   
        setLocations(data)
 
        if(data.locations && Array.isArray(data.locations)) {
        
          data.locations.forEach((location) => {
            getLocationsCalendar(location.id, method);
          })
        } else {
          getLocationsCalendar(data.id, method);
        }
        
        return data; 
      })
  }

  const getLocationsCalendar = (id, method) => {

    const url = `https://api.zapiet.com/v4.0/${method}/locations/${id}/calendar?` + new URLSearchParams({
      shop: 'nute-foods.myshopify.com'
    });


    return fetch(url)
      .then((res) => {
        return res.json();
      })
      .then(data=> {
        setLocationCalendars({...locationCalendars, [id]: data});
        return data;
      });
  }

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        width,
        addVariantToCart,
        addMethodToCheckout,
        getLineItemProducts,
        lineItemProducts,
        setBag,
        setNote,
        addProductToCreate,
        addCreateToCart,
        createYourOwn,
        addDiscount,
        removeDiscount,
        updateLineItemsWithZapietId,
        removeLineItem,
        updateLineItem,
        getCurrentUser,
        getCurrentUsername,
        getCustomerObject,
        customerCreate,
        customerUpdate,
        customerRecover,
        login,
        logout,
        username,
        getLocations,
        getLocationsCalendar,
        checkout,
        loading,
        locations,
        locationCalendars,
        didJustAddToCart,
        toggleCart,
        cartOpen,
        toggleMenu,
        toggleMobileMenu,
        menuOpen,
        mobileMenuOpen,
        toggleLogin,
        toggleTime,
        loginOpen,
        footerTrigger,
        setFooterTrigger,
        conceptOpen,
        setConceptOpen,
        conceptGranolaOpen,
        setConceptGranolaOpen
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}

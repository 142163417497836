exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-account-account-layout-jsx": () => import("./../../../src/pages/account/account-layout.jsx" /* webpackChunkName: "component---src-pages-account-account-layout-jsx" */),
  "component---src-pages-account-create-jsx": () => import("./../../../src/pages/account/create.jsx" /* webpackChunkName: "component---src-pages-account-create-jsx" */),
  "component---src-pages-account-index-jsx": () => import("./../../../src/pages/account/index.jsx" /* webpackChunkName: "component---src-pages-account-index-jsx" */),
  "component---src-pages-account-login-jsx": () => import("./../../../src/pages/account/login.jsx" /* webpackChunkName: "component---src-pages-account-login-jsx" */),
  "component---src-pages-account-orders-jsx": () => import("./../../../src/pages/account/orders.jsx" /* webpackChunkName: "component---src-pages-account-orders-jsx" */),
  "component---src-pages-create-your-own-jsx": () => import("./../../../src/pages/create-your-own.jsx" /* webpackChunkName: "component---src-pages-create-your-own-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-partner-jsx": () => import("./../../../src/pages/partner.jsx" /* webpackChunkName: "component---src-pages-partner-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-products-icebar-index-jsx": () => import("./../../../src/pages/products/icebar/index.jsx" /* webpackChunkName: "component---src-pages-products-icebar-index-jsx" */),
  "component---src-pages-products-index-jsx": () => import("./../../../src/pages/products/index.jsx" /* webpackChunkName: "component---src-pages-products-index-jsx" */),
  "component---src-pages-products-shopify-collection-handle-index-jsx": () => import("./../../../src/pages/products/{ShopifyCollection.handle}/index.jsx" /* webpackChunkName: "component---src-pages-products-shopify-collection-handle-index-jsx" */),
  "component---src-pages-products-shopify-product-product-type-shopify-product-handle-jsx": () => import("./../../../src/pages/products/{ShopifyProduct.productType}/{ShopifyProduct.handle}.jsx" /* webpackChunkName: "component---src-pages-products-shopify-product-product-type-shopify-product-handle-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-pages-we-jsx": () => import("./../../../src/pages/we.jsx" /* webpackChunkName: "component---src-pages-we-jsx" */)
}


import * as React from "react"
import { CookiesProvider } from 'react-cookie';
import { StoreProvider } from "./src/context/store-context"
import Cover from './src/components/Cover/Cover';

import "./src/styles/reset.css"
import "./src/styles/variables.css"
import "./src/styles/global.css"

export const wrapRootElement = ({ element, props }) => (
  <StoreProvider value={{ menuOpen: false }}>
    <CookiesProvider>
      {element}
    </CookiesProvider>
  </StoreProvider>
)

export const wrapPageElement = ({ element, props }) => {
  return <><Cover/>{element}</>
}
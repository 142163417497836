import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const CoverEl = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background:#F2EADE;
  z-index: 100;
  opacity: ${props => props.loaded ? 0 : 1};
  transition: opacity .2s ease;
  pointer-events: none;
`
const Cover = () => {
  const [ Loaded, setLoaded ] = useState(false);

  useEffect(() => { 
    if(!Loaded) {
      setTimeout(function() {
        setLoaded(true);
      }, 1000)
    }
  }, []);

  return (
    <>
      <CoverEl loaded={Loaded}/>
    </>
  )
}

export default Cover;

export const encodeZapietId = (params) => {
  const ret = [];
  for (let d in params) {
      if (params[d]) {
          ret.push(d + '=' + params[d]);
      }
  }
  return ret.join('&');
}


export const getMethodKey = (method) => {
  if (method == 'delivery') {
      return 'D';
  } else if (method == 'pickup') {
      return 'P';
  } else {
      return 'S';
  }
}

export const getZapietId = (params) => {
    var fomatted_date = '';
    if (params.date && !params.time) {
        var fomatted_date = params.date.replace(/\//g, "-");
        fomatted_date = fomatted_date + 'T00:00:00Z';
    } else if (params.date && params.start_time) {
        var fomatted_date = params.date.replace(/\//g, "-");
        fomatted_date = fomatted_date + 'T' + params.start_time + ':00Z';
    }
    return encodeZapietId({
        M: getMethodKey(params.method),
        L: (params.location_id) ? params.location_id : "",
        D: fomatted_date,
        P: ""
    });
}